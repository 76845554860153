
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import MemberList from "@/components/List/MemberList.vue";

@Component({
  name: "MemberModal",
  components: { MemberList },
  filters: {
    //局部过滤器
  },
})
export default class MemberModal extends Vue {
  public memberList: any[] = [];

  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop({ default: false, type: Boolean })
  visible!: boolean;
  @Prop({ required: true, type: Object })
  cooperation!: any;
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  //@Ref()

  // 获取成员信息
  queryMemberList() {
    this.$apis
      .coopFileInfoQueryUsersByCoopId({
        coopId: this.cooperation.id,
      })
      .then((res: any) => {
        if (res.code === "200") {
          const list = res.data.users.map((i: any) => {
            return {
              ...i,
              role: i.coopFilePrivilege,
              disabled: i.coopFilePrivilege === "super",
            };
          });
          // 按权限将成员进行排序
          const superList: any[] = [];
          const editList: any[] = [];
          const viewList: any[] = [];
          list.forEach((item: any) => {
            if (item.role === "super") {
              superList.push(item);
            } else if (item.role === "edit") {
              editList.push(item);
            } else {
              viewList.push(item);
            }
          });
          this.memberList = [...superList, ...editList, ...viewList];
        }
      });
  }

  handleCancel() {
    this.$emit("close");
  }

  //创建前钩子函数
  created(): void {
    this.queryMemberList();
  }
}
