
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import MenuSimple from "@/views/mydoc/menuSimple.vue";
import topFilter from "./json/topFilter";
import dayjs from "dayjs";
import { namespace } from "vuex-class";
import SaveModal from "@/views/popup/moveModal.vue";

const UserModule = namespace("user");
const CooperationModule = namespace("cooperation");

import FileHistory from "./FileHistory.vue";
import MemberModal from "./MemberModal.vue";
import TranPowerModal from "./TranPowerModal.vue";
import CooperationModal from "@/views/mydoc/CooperationModal.vue";
import FileModal from "@/components/Modal/FileModal.vue";

const columns = [
  {
    title: "名称",
    dataIndex: "coopFileName",
    align: "left",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "协作类型",
    dataIndex: "userCooPType",
    align: "left",
    scopedSlots: { customRender: "userCooPType" },
  },
  {
    title: "修改时间",
    dataIndex: "updateTime",
    align: "center",
    scopedSlots: { customRender: "updateTime" },
  },
  {
    title: "文件大小",
    dataIndex: "coopFileSize",
    align: "center",
    scopedSlots: { customRender: "size" },
  },
  {
    title: "协作成员",
    dataIndex: "member",
    align: "left",
    scopedSlots: { customRender: "member" },
    width: "150px",
  },
  {
    title: "操作",
    dataIndex: "",
    align: "right",
    scopedSlots: { customRender: "action" },
    width: "285px",
  },
];
const initiatorActions = [
  {
    actionCode: "save",
    title: "另存为",
  },
  {
    actionCode: "history",
    title: "历史版本",
  },
  {
    actionCode: "member",
    title: "查看协作成员",
  },
  {
    actionCode: "tranPower",
    title: "转让协作",
  },
  {
    actionCode: "cancel",
    title: "取消协作",
  },
  {
    actionCode: "delete",
    title: "删除协作",
  },
];
const joinActions = [
  {
    actionCode: "save",
    title: "另存为",
  },
  {
    actionCode: "history",
    title: "历史版本",
  },
  {
    actionCode: "member",
    title: "查看协作成员",
  },
  {
    actionCode: "exit",
    title: "退出协作",
  },
];

@Component({
  name: "Cooperation",
  components: {
    MenuSimple,
    FileHistory,
    MemberModal,
    CooperationModal,
    SaveModal,
    TranPowerModal,
    FileModal,
  },
  //局部过滤器
  filters: {
    // 时间格式化
    timeFormat(time: string | number) {
      return dayjs(time).format("YYYY-MM-DD HH:mm");
    },
    // 文件大小
    unitTransform(size: number) {
      let val: string | number;
      if (size < 1024) {
        val = size + "B";
      } else if (size < 1024 * 1024) {
        val = size / 1024;
        val = val.toFixed(2) + "KB";
      } else if (size < 1024 * 1024 * 1024) {
        val = size / (1024 * 1024);
        val = val.toFixed(2) + "MB";
      } else {
        val = size / (1024 * 1024 * 1024);
        val = val.toFixed(2) + "GB";
      }
      return val;
    },
  },
})
export default class Cooperation extends Vue {
  @UserModule.State("userId") userId!: string;
  @UserModule.State("name") userName!: string;
  @UserModule.State("functionCodes") functionCodes!: string;
  @UserModule.State("companyId") companyId!: string;
  @CooperationModule.State("triggerListquery") trigger!: number;

  public tabIndex: number = 0; // 标签页
  public listData: any[] = []; // 表格数据
  public topFilterList: any[] = topFilter; // 排序列表
  public sortIndicatorList: string[] = ["updateTime"]; // 排序方式
  public sortRuleList: string[] = ["asc"]; // 排序列表排序规则
  public detailShow: boolean = false; // 文件详情
  public columns: any = columns; // 表头
  public initiatorActions: any = initiatorActions; // 发起操作列表
  public joinActions: any = joinActions; // 加入操作列表
  public total: number = 0; // 页数
  public selectedRowKeys: Array<number | string> = []; // 多选项
  public loading: boolean = false; //加载
  public actionRecord: any = {}; // 操作表格项
  public historyShow: boolean = false; // 查看历史版本
  public memberModalShow: boolean = false; // 成员列表

  public cooperationVisible: boolean = false; // 添加/编辑成员显示
  public cooperationLoading: boolean = false;

  public transPowerVisible: boolean = false; // 转让协作显示

  public saveModalShow: boolean = false; // 另存为显示
  public saveOptions: any = { checkIdList: [] }; // 另存为参数

  public fileCooperationVisible: boolean = false;
  public cooperationType: string = "";
  public cooperationFileVisible: boolean = false;
  public cooperationFile: any = {};

  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  //@Prop()

  // 选择的文件
  get checkFile(): any {
    return this.listData.find((i) => i.id === this.selectedRowKeys[0]);
  }
  get noScrollQuery(): boolean {
    return this.listData.length === this.total;
  }

  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  @Watch("sortRuleList", { deep: true })
  sortRuleListChange() {
    console.log("变更排序方式");
    this.setData();
  }
  @Watch("sortIndicatorList", { deep: true })
  sortIndicatorListChange() {
    console.log("变更排序规则");
    this.setData();
  }
  @Watch("trigger")
  triggerChange() {
    console.log("协作文件修改,进行刷新");
    this.queryList();
  }
  //ref 引用元素 Ref('ref')
  @Ref("fileContainer")
  fileContainerRef!: HTMLDivElement;

  // 右键菜单自定义
  onContextMenu(e: any, refName: string = "contextContent", el: any = {}) {}
  // 点击排序
  handleClick(el: any) {
    if (el.func === "sort") {
      // 维护排序规则数据, watch监听会自动给列表做排序
      if (el.sortFlag) {
        // 升序/倒序
        this.sortRuleList = [el.options.asc];
      } else {
        // 排序指标(文件名/创建时间等)
        this.sortIndicatorList = [el.options.indicator];
      }
      // 修改排序展示的文字及图标
      this.topFilterList.map((el: any) => {
        // 对应指标对象
        let indicitem: any = this.topFilterList[0].children.find(
            (el: any) => el._value === this.sortIndicatorList[0]
          ),
          // 升序/降序对象
          ruleItem: any = this.topFilterList[0].children.find(
            (el: any) => el._value === this.sortRuleList[0]
          );
        if (indicitem && ruleItem) {
          // 修改排序展示效果
          return Object.assign(el, {
            text: `按${indicitem.text}${ruleItem.text}`,
            iconfont: ruleItem._value === "asc" ? "shengxu" : "jiangxu",
          });
        } else {
          return el;
        }
      });
    }
  }

  // 排序规则
  sortWithIndicator(a: any, b: any) {
    let indc = this.sortIndicatorList[0] || "";
    switch (indc) {
      case "coopFileName":
        return a[indc].localeCompare(b[indc], "zh-CN"); // 拼音排序
      case "createTime":
      case "updateTime":
        return dayjs(a[indc]).valueOf() - dayjs(b[indc]).valueOf(); // 时间戳排序
      default:
        return parseInt(a[indc]) - parseInt(b[indc]); // 数字/数字型字符串排序
    }
  }
  // 文件详情显示
  handleDetail() {
    this.detailShow = true;
  }

  // 版本回退
  handleRecover(value: any) {
    this.$apis
      .coopHistoryRollback({
        fileCoopId: value.coopId,
        id: value.id,
      })
      .then((res: any) => {
        if (res.code === "200") {
          this.$message.success("操作成功");
          this.historyShow = false;
          this.queryList();
        }
      });
  }

  onFileScroll(e: any) {
    if (this.noScrollQuery) return;
    const table: HTMLDivElement = e.target;
    if (
      table.scrollHeight -
        table.scrollTop -
        this.fileContainerRef.offsetHeight <=
        10 &&
      !this.loading
    ) {
      this.loading = true;
      let list = this.listData;
      let pageNum = list.length / 10 + 1;
      this.$apis
        .coopFileInfoQueryCoopFileList({
          companyId: 'person',
          isPage: 1,
          pageNum: pageNum,
          pageSize: 10,
        })
        .then((res: any) => {
          if (res.code === "200") {
            this.listData = list.concat(res.data.list);
            this.setData();
            const coopIds = res.data.list.map((i: any) => i.id);
            this.queryAvatarImageList(coopIds);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  // 数据设置
  setData(list?: any[]) {
    let setList;
    if (list) {
      setList = list;
    } else {
      setList = this.listData;
    }
    this.listData = (setList || []).sort((a: any, b: any) => {
      if (this.sortRuleList[0] === "asc") {
        // 升序
        return this.sortWithIndicator(b, a);
      } else {
        // 降序
        return this.sortWithIndicator(a, b);
      }
    });
  }
  // 获取列表
  queryList() {
    this.loading = true;
    this.$apis
      .coopFileInfoQueryCoopFileList({
        companyId: 'person',
        isPage: 1,
        pageNum: 1,
        pageSize: 20,
      })
      .then((res: any) => {
        if (res.code === "200") {
          this.setData(res.data.list);
          this.total = res.data.total;
          const coopIds = res.data.list.map((i: any) => i.id);
          this.queryAvatarImageList(coopIds);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
  // 获取头像列表
  queryAvatarImageList(ids: Array<number | string>) {
    this.$apis
      .coopFileInfoQueryUserImageList({
        coopIds: ids,
      })
      .then((res: any) => {
        if (res.code === "200") {
          this.setAvatarImage(res.data);
        }
      });
  }

  // 设置头像
  setAvatarImage(list: any[]) {
    list.forEach((i) => {
      this.listData.forEach((item) => {
        if (i.coopFileId === item.id) {
          this.$set(item, "member", i.users);
        }
      });
    });
  }

  // 多选按钮
  onSelectChange(selectedRowKeys: Array<number | string>): void {
    this.selectedRowKeys = selectedRowKeys;
  }

  // 另存为
  saveOk(targetId: string, sourceIds: Array<number | string>) {
    console.log(targetId, sourceIds);
    this.$apis
      .documentUserFileSaveAs({
        targetId,
        sourceIds,
      })
      .then((res: any) => {
        if (res.code == 200) {
          this.$message.success("文件保存成功");
          this.saveModalShow = false;
        }
      });
  }

  // 转让确认
  transPowerOk(params: any) {
    this.$apis.coopUserRelTranPower(params).then((res: any) => {
      if (res.code == 200) {
        this.$message.success("转让成功");
        this.queryList();
        this.transPowerVisible = false;
      }
    });
  }

  handleAction(action: string, value: any) {
    const that = this;
    switch (action) {
      case "save":
        this.saveOptions.checkIdList = [value.id];
        this.saveModalShow = true;
        break;
      case "history":
        this.actionRecord = value;
        this.historyShow = true;
        break;
      case "member":
        this.actionRecord = value;
        value.userCooPType === "我发起的"
          ? (this.cooperationVisible = true)
          : (this.memberModalShow = true);
        break;
      case "cancel":
        this.$confirm({
          title: "取消协作",
          content:
            "取消协作后，自动为各成员保存最新版本为副本，修改互不影响，确定要取消吗？",
          okText: "确定",
          okType: "danger",
          cancelText: "取消",
          onOk() {
            that.$apis
              .coopFileInfoCancel({
                ids: value.id,
              })
              .then((res: any) => {
                if (res.code === "200") {
                  that.$message.success("取消成功");
                  that.queryList();
                }
              });
          },
          onCancel() {
            console.log("Cancel");
          },
        });
        break;
      case "delete":
        this.$confirm({
          title: "删除协作",
          content: "删除协作后，仅你自己可编辑和访问该文件，确定要取消吗？",
          okText: "确定",
          okType: "danger",
          cancelText: "取消",
          onOk() {
            that.$apis
              .coopFileInfoDelete({
                ids: value.id,
              })
              .then((res: any) => {
                if (res.code === "200") {
                  that.$message.success("删除成功");
                  that.queryList();
                }
              });
          },
          onCancel() {
            console.log("Cancel");
          },
        });
        break;
      case "tranPower":
        this.actionRecord = value;
        this.transPowerVisible = true;
        break;
      case "exit":
        this.$confirm({
          title: "退出协作",
          content: "退出协作后，你将不能在编辑和访问该文件，确定要退出吗？",
          okText: "确定",
          okType: "danger",
          cancelText: "取消",
          onOk() {
            that.$apis
              .coopFileInfoExit({
                ids: value.id,
              })
              .then((res: any) => {
                if (res.code === "200") {
                  that.$message.success("退出成功");
                  that.queryList();
                }
              });
          },
          onCancel() {
            console.log("Cancel");
          },
        });
        break;
      default:
        break;
    }
  }

  customRow(record: any) {
    return {
      on: {
        // 事件
        click: () => {
          let arr: Array<string | number> = this.selectedRowKeys;
          if (arr.includes(record.id) && arr.length === 1) {
            arr.splice(
              arr.findIndex((item: any) => item === record.id),
              1
            );
          } else {
            this.selectedRowKeys = [record.id];
          }
        }, // 点击行
        dblclick: () => {
          if (this.functionCodes.includes("VIEW_OFFICE_ONLINE")) {
            let query = {
              coopId: record.id,
              moduleType: "coop",
              fileExtension: record.extensionName,
              fileName: record.coopFileName,
              ot: "view",
            };
            let routeData = this.$router.resolve({
              name: "VIEW_OFFICE_ONLINE",
              query,
            });
            window.open(routeData.href, "_blank"); // 新窗口打开
          } else {
            this.$message.warning("在线文档功能未开启,请联系管理员");
          }
        },
        contextmenu: (el: any) => {
          // 阻止冒泡, 阻止默认事件.stop.prevent
          el.stopPropagation();
          el.preventDefault();
          // IE兼容
          el.cancelBubble = true;
          el.returnValue = false;
        },
      },
    };
  }

  // 点击协作
  handleCooperation(type: string, value: any): void {
    this.cooperationType = type;
    this.cooperationFile = value;
    this.cooperationFileVisible = true;
    this.fileCooperationVisible = false;
  }

  //创建前钩子函数
  created(): void {
    this.queryList();
  }
}
