
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

const UserModule = namespace("user");
@Component({
  name: "FileHistory",
  components: {},
  filters: {
    //局部过滤器
  },
})
export default class FileHistory extends Vue {
  @UserModule.State("userId") userId!: string;
  @UserModule.State("name") userName!: string;
  @UserModule.State("functionCodes") functionCodes!: string;

  public historyList: any[] = [];
  public loading: boolean = false;
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop({ default: false, type: Boolean })
  visible!: boolean;
  @Prop({ required: true })
  cooperation!: any;
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  //@Ref()

  // 弹出框关闭
  handleCancel() {
    this.$emit("close");
  }
  // 获取历史记录
  queryHistoryList() {
    this.loading = true;
    this.$apis
      .coopHistoryPageList({
        coopId: this.cooperation.id,
        isPage: 0,
      })
      .then((res: any) => {
        if (res.code === "200") {
          this.historyList = res.data.list;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }
  // 操作
  handleAction(action: string, value: any) {
    switch (action) {
      case "view":
        if (this.functionCodes.includes("VIEW_OFFICE_ONLINE")) {
          let query = {
            coopId: this.cooperation.id,
            versionNum: value.versionNum,
            moduleType: "history",
            fileExtension: this.cooperation.extensionName,
            fileName: this.cooperation.coopFileName,
            ot: "view",
          };
          let routeData = this.$router.resolve({
            name: "VIEW_OFFICE_ONLINE",
            query,
          });
          window.open(routeData.href, "_blank"); // 新窗口打开
        } else {
          this.$message.warning("在线文档功能未开启,请联系管理员");
        }
        break;

      case "recover":
        this.$emit("recover", value);
        break;

      default:
        break;
    }
  }

  //创建前钩子函数
  created(): void {
    this.queryHistoryList();
  }
}
