
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import MemberList from "@/components/List/MemberList.vue";

@Component({
  name: "TranPowerModal",
  components: { MemberList },
  filters: {
    //局部过滤器
  },
})
export default class TranPowerModal extends Vue {
  public OkLoading: boolean = false;

  public loading: boolean = false;

  public memberList: any[] = [];

  public selectMember: any = {};

  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop({ default: false, type: Boolean })
  readonly visible!: boolean;
  @Prop()
  readonly cooperation!: any;
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  //@Watch('')
  //ref 引用元素 Ref('ref')
  //@Ref()

  // 是否选中
  isSelect(value: any) {
    return this.selectMember.userId === value.userId;
  }

  // 选择成员
  handleSelect(value: any) {
    this.selectMember = value;
  }

  // 点击关闭
  handleCancel() {
    this.$emit("close");
  }

  // 点击确认
  handleOk() {
    if (this.memberList.length === 0) {
      this.$emit("close");
    } else if (this.selectMember.userId) {
      let params = {
        id: this.cooperation.id,
        userId: this.selectMember.userId,
      };
      this.OkLoading = true;
      this.$emit("Ok", params);
    } else {
      this.$message.warning("请选择需要转让的成员");
    }
  }

  // 获取协作成员
  queryMemberList() {
    this.loading = true;
    this.$apis
      .coopFileInfoQueryUsersByCoopId({
        coopId: this.cooperation.id,
      })
      .then((res: any) => {
        if (res.code === "200") {
          const list = res.data.users.map((i: any) => {
            return {
              ...i,
              role: i.coopFilePrivilege,
            };
          });
          // 按权限将成员进行排序
          const editList: any[] = [];
          const viewList: any[] = [];
          list.forEach((item: any) => {
            if (item.role === "edit") {
              editList.push(item);
            } else if (item.role === "view") {
              viewList.push(item);
            }
          });
          this.memberList = [...editList, ...viewList];
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  //创建前钩子函数
  created(): void {
    this.queryMemberList();
  }
}
