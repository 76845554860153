// 排序
export default
    [
        {
            text: "按创建时间升序",
            iconfont: "shengxu",
            children: [
                {
                    text: "名称",
                    name: "nameSort",
                    func: "sort",
                    _value: "coopFileName",
                    options: {
                        indicator: "coopFileName",
                    },
                },
                {
                    text: "创建时间",
                    name: "createTimeSort",
                    func: "sort",
                    _value: "createTime",
                    options: {
                        indicator: "createTime",
                    },
                },
                // {
                //     text: "修改时间",
                //     name: "updateTimeSort",
                //     func: "sort",
                //     _value: "updateTime",
                //     options: {
                //         indicator: "updateTime",
                //     },
                // },
                {
                    text: "文件大小",
                    name: "sizeSort",
                    func: "sort",
                    _value: "coopFileSize",
                    options: {
                        indicator: "coopFileSize",
                    },
                },
                {
                    text: "升序",
                    name: "ascending",
                    func: "sort",
                    _value: "asc",
                    options: {
                        asc: "asc",
                    },
                    sortFlag: true,
                    topLine: true,
                },
                {
                    text: "降序",
                    name: "descending",
                    func: "sort",
                    _value: "desc",
                    options: {
                        asc: "desc",
                    },
                    sortFlag: true,
                },
            ],
        },
    ]

